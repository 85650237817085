@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: inter;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 10px;
}
body {
  background: #f5f6f7;
}

.scrollbar::-webkit-scrollbar {
  display: none;
}

.scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
